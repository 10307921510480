<template>
  <div class="page">
    <div class="search-box section">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="tenantId">
          <el-select v-model="query.tenantId" placeholder="请选择租户">
            <el-option label="S-101001" value="S-101001"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="goSearch">搜索</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box section">
      <div class="op-box">
        <div class="op-content">
          共计<span class="total-size">{{ pagination.totalSize }}</span
        >条信息
        </div>
        <div class="op-extra">
          <icon-button
              v-if="hasRights('001004003002001')"
              icon="add-select"
              @click.native="toAdd"
          />
          <icon-button
              v-if="hasRights('001004003002002')"
              icon="share"
              title="导出"
              @click.native="exportData"
          />
        </div>
      </div>
      <div>
        <el-table
            v-loading="isLoading"
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="模板编号" prop="templateCode">
          </el-table-column>
          <el-table-column label="模板名称" prop="templateName">
          </el-table-column>
          <el-table-column label="租户ID" prop="tenantId"></el-table-column>
          <el-table-column label="创建时间" prop="createDate">
            <template slot-scope="scope">
              <span>{{ dateFormat(scope.row.createDate) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="当前状态" prop="isCurrent">
            <template slot-scope="scope">
              <span v-if="scope.row.isCurrent === 0" style="color: green"
              >已启用</span
              >
              <span v-else style="color: red">未启用</span>
            </template>
          </el-table-column>
          <el-table-column label="版本号" prop="version"></el-table-column>
          <el-table-column align="center" label="操作" prop="" width="180">
            <template slot-scope="scope">
              <el-button
                  v-if="hasRights('001004003002003')"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="doEdit(scope.row)"
              >编辑
              </el-button
              >
              <el-button
                  v-if="scope.row.isCurrent === 0 && hasRights('001004003002004')"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="checkStatus(scope.row.id, 1)"
              >禁用
              </el-button
              >
              <el-button
                  v-if="scope.row.isCurrent !== 0 && hasRights('001004003002004')"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="checkStatus(scope.row.id, 0)"
              >启用
              </el-button
              >
              <el-button
                  v-if="hasRights('001004003002005')"
                  class="common-text"
                  size="small"
                  type="text"
                  @click="remove(scope.row)"
              >移除
              </el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :before-close="onDialogBeforeClosed"
        :visible.sync="dialogVisible"
        append-to-body
        class="common-dialog"
        title="创建小票模版"
        width="800px"
    >
      <el-form
          ref="model"
          v-loading="isLoading"
          :model="model"
          :rules="rules"
          size="medium"
      >
        <el-form-item
            label="模板名称"
            label-width="120px"
            prop="templateName"
            required
        >
          <el-input
              v-model="model.templateName"
              placeholder="模板名称"
              style="width: 400px"
          />
        </el-form-item>
        <el-form-item label="租户" label-width="120px" prop="tenantId" required>
          <el-select v-model="model.tenantId" placeholder="请选择租户">
            <el-option label="S-101001" value="S-101001"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
            label="版本号"
            label-width="120px"
            prop="version"
            required
        >
          <el-input
              v-model="model.version"
              placeholder="版本号"
              style="width: 400px"
          />
        </el-form-item>
        <el-form-item
            label="小票标题"
            label-width="120px"
            prop="ticketTitle"
            required
        >
          <el-input
              v-model="model.ticketTitle"
              placeholder="小票标题"
              style="width: 400px"
          />
        </el-form-item>
        <el-form-item
            label="小票模版内容"
            label-width="120px"
            prop="ticketContent"
            required
        >
          <div style="width: 500px">
            <JsonEditorVue
                v-model="model.ticketContent"
                v-bind="{
                /* 局部 props */
              }"
            />
          </div>
        </el-form-item>
        <el-form-item
            label="注册二维码"
            label-width="120px"
            prop="registQrcode"
        >
          <!-- <el-input
            placeholder="注册二维码"
            v-model="model.registQrcode"
            style="width: 400px"
          /> -->
          <el-upload
              ref="registQrcodeUpload"
              :http-request="doUploadRegistQrcode"
              :show-file-list="false"
              action=""
              class="registQrcodeUpload"
              list-type="picture"
          >
            <Picture
                v-if="model.registQrcode"
                :attachId="model.registQrcode"
                class="regist_qrcode"
            />
            <i v-else class="el-icon-plus qrcode-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" label-width="120px" prop="remark">
          <el-input
              v-model="model.remark"
              placeholder="备注"
              style="width: 400px"
              type="textarea"
          />
        </el-form-item>
        <el-form-item
            label="运营公司"
            label-width="120px"
            prop="settlementName"
        >
          <el-input
              v-model="model.settlementName"
              placeholder="运营公司"
              style="width: 400px"
          />
        </el-form-item>
        <el-form-item label="客服电话" label-width="120px" prop="customer">
          <el-input
              v-model="model.customer"
              placeholder="客服电话"
              style="width: 400px"
          />
        </el-form-item>
        <el-form-item label="温馨提示" label-width="120px" prop="tips">
          <el-input
              v-model="model.tips"
              placeholder="温馨提示"
              style="width: 400px"
              type="textarea"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
            :disabled="dialogVisible && isLoading"
            type="primary"
            @click="save"
        >确定
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";

import {getTicketTemplateByPage, removeTicketTemplate, saveCreate, saveEdit,} from "@/api/patrolInspection";
import {doExport, uploadFileToOSS} from "@/api/common";

export default {
  name: "inspector_ticket_template",
  mixins: [BaseMixin, Permission],
  data() {
    return {
      query: {
        tenantId: "",
        isExport: "0", //是否导出
        exportSize: 1, //导出条数
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      model: {
        tenantId: "",
        version: "",
        ticketTitle: "",
        ticketContent: [],
        registQrcode: "",
        remark: "",
        settlementName: "",
        customer: "",
        tips: "",
        id: "",
        templateName: "",
      },
      rules: {
        templateName: [{required: true, message: "模版名称不能为空"}],
        tenantId: [{required: true, message: "租户不能为空"}],
        version: [{required: true, message: "版本号不能为空"}],
        ticketTitle: [{required: true, message: "小票标题不能为空"}],
        ticketContent: [{required: true, message: "小票内容不能为空"}],
      },
      isLoading: false,
      dialogVisible: false,
    };
  },
  methods: {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader(); //定义方法读取文件
        reader.readAsDataURL(file); //开始读文件  本身是图片的二进制数据 进行base64加密形成字符串
        reader.onload = () => resolve(reader.result); //成功返回对应的值 reader.result可以直接放在img标签中使用
        reader.onerror = (error) => reject(error); //失败返回失败信息
      });
    },
    async doUploadRegistQrcode(file) {
      const isLimit = file.file.size / 1024 < 1000;
      if (!isLimit) {
        this.$message.warning("图片大小不能超过1M! 请重新上传");
        return;
      }
      const base64 = await this.getBase64(file.file);
      const res = await uploadFileToOSS({
        image: base64,
      });
      if (res && res.code === 30 && res.result) {
        this.model.registQrcode = res.returnObject.id;
      }
    },
    async search() {
      this.isLoading = true;
      const res = await getTicketTemplateByPage({
        query: this.query.tenantId,
        page: this.pagination.pageNo,
        size: this.pagination.pageSize,
        isExport: this.query.isExport,
        exportSize: this.query.exportSize,
      });
      this.isLoading = false;
      if (res && res.code === 30 && res.result) {
        this.dataList = res.returnObject.list;
        this.pagination.totalSize = res.returnObject.count;
        this.pagination.totalPages = res.returnObject.totalPage;
        this.form.exportSize = res.returnObject.count;
      }
    },
    goSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    resetForm() {
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = {
        query: this.query.tenantId,
        page: this.pagination.pageNo,
        size: this.pagination.pageSize,
        isExport: "1",
        exportSize: this.form.exportSize,
      };
      // window.open("http://172.16.69.129:81" + "/examineManageController/getPersonnelManage?" + objectToQueryString(params));
      doExport(
          "/template/getTemplateByTenantOrRegionId",
          params,
          "小票模板.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;
    },
    toAdd() {
      this.dialogVisible = true;
    },
    remove(row) {
      if (row.isCurrent === 0) {
        this.$message.warning("使用中的模版无法删除！");
        return;
      }

      this.$confirm("此操作将删除该小票模版, 是否继续?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            const res = await removeTicketTemplate(row.id);
            if (res && res.code === 30 && res.result) {
              this.$message.success("操作成功！");
              this.goSearch();
            }
          })
          .catch(() => {
          });
    },
    doEdit(row) {
      let contentModel = JSON.parse(row.content);
      this.model.ticketTitle = contentModel.ticketTitle;
      this.model.ticketContent = contentModel.ticketContent;
      this.model.registQrcode = contentModel.registQrcode;
      this.model.remark = contentModel.remark;
      this.model.settlementName = contentModel.settlementName;
      this.model.customer = contentModel.customer;
      this.model.tips = contentModel.tips;

      // 租户以及版本
      this.model.tenantId = row.tenantId;
      this.model.version = row.version;

      this.model.id = row.id;

      this.model.templateName = row.templateName;

      this.dialogVisible = true;
    },

    async checkStatus(id, status) {
      const msg =
          status === 0
              ? "该操作将启用此模版，是否继续？"
              : "该操作将禁用此模版，是否继续？";

      this.$confirm(msg, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            const res = await saveEdit({
              id: id,
              isCurrent: status,
            });

            if (res && res.code === 30 && res.result) {
              this.$message.success("操作成功！");
              this.goSearch();
            }
          })
          .catch(() => {
          });
    },

    save() {
      this.$refs.model.validate(async (valid) => {
        if (!valid) {
          return false;
        }

        const api = this.model.id === "" ? saveCreate : saveEdit;
        const {
          ticketTitle,
          ticketContent,
          registQrcode,
          remark,
          settlementName,
          customer,
          tips,
          tenantId,
          version,
          templateName,
        } = this.model;

        this.isLoading = true;
        const res = await api({
          id: this.model.id,
          content: JSON.stringify({
            ticketTitle,
            ticketContent,
            registQrcode,
            remark,
            settlementName,
            customer,
            tips,
          }),
          tenantId: tenantId,
          version: version,
          templateName: templateName,
        });
        this.isLoading = false;
        if (res && res.code === 30 && res.result) {
          this.$message.success("保存成功！");
          this.$refs.model.resetFields();
          this.dialogVisible = false;
          this.goSearch();
        }
      });
    },
    onDialogBeforeClosed(done) {
      this.$refs.model.resetFields();
      this.isLoading = false;
      done();
    },
  },
  mounted() {
    this.goSearch();
  },
};
</script>

<style lang="less" scoped>
.registQrcodeUpload {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}

.registQrcodeUpload .el-upload:hover {
  border-color: #0768FD;
}

.qrcode-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.regist_qrcode {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
